<template>
  <main class="main__available">
    <div class="_container">
      <div class="available">
        <h2 class="available__title">{{getTranslation('Доступные лотереи')}}</h2>
        <div class="available__container">
          <AvailableCard
              v-for="room in activeRooms"
              :room="room"
              :user="user"
              @click="openGame(room)"
          />
        </div>
      </div>
    </div>
    <div class="dashboard-faq">
      <div class="_container">
        <h2 class="dashboard-faq__title">{{getTranslation('Часто задаваемые вопросы')}}</h2>
        <div class="dashboard-faq__container">
          <DashboardFAQDetails
              v-for="faq in faqList"
              :key="faq.id"
              :faq="faq"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AvailableCard from "@/components/AvailableCard/AvailableCard.vue";
import DashboardFAQDetails from "@/components/UI/DashboardFAQDetails/DashboardFAQDetails.vue";
import {mapGetters, mapState} from "vuex";

export default {
  components: {
    DashboardFAQDetails,
    AvailableCard
  },
  props: {
    user: {}
  },
  data () {
    return {

    }
  },
  methods: {
    openGame(room) {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/lottery/' + room.id)
      } else {
        this.$router.push('/auth/authorization')
      }
    }
  },
  mounted() {
    this.$store.dispatch('rooms/getActiveRoom')
    this.$store.dispatch('faq/faqList')
  },
  computed: {
    ...mapState('rooms', ['activeRooms']),
    ...mapState('faq', ['faqList']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('locale', ['getTranslation'])
  },
  beforeRouteLeave(to, from, next) {
    to.query.previousPath = from.path;
    next();
  }
}
</script>

<style lang="scss" scoped>
@import "availableLotteries";
</style>