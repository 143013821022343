<template>
  <div class="operations-block" @click="openOperationsBlock = !openOperationsBlock">
    <div class="operations-block__main">
      <div class="operations-block__left">
        <div class="operations-block__status">
          <div
              :class="{ 'open': openOperationsBlock }"
              class="operations-block__button"
          >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.09128 7L4.08852e-07 1.55531L1.45436 -2.86119e-07L8 7L1.45436 14L-6.71376e-08 12.4447L5.09128 7Z" fill="#697383"/>
            </svg>
          </div>
          <div class="operations-block__balance">
            <span>{{ transaction?.type }}</span>
            <div>
              <img :src="statusTransaction.img" v-if="statusTransaction.img" :alt="statusTransaction.text">
              <span :class="statusTransaction.class">
                {{ statusTransaction.text }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="operations-block__right">
        <div class="operations-block__money">
          <div>
            <span>{{getTranslation('Сумма пополнения')}}</span>
            <span>{{ transaction?.amount }} {{ transaction?.currency }}</span>
          </div>
        </div>
        <div class="operations-block__date">
          <div>
            <span v-if="transaction?.type === 'Пополнение'">{{getTranslation('Дата пополнения')}}</span>
            <span v-else>{{getTranslation('Дата вывода')}}</span>
            <span>{{ transaction?.date }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
        class="operations-block__collapse"
        :class="{ 'open': openOperationsBlock }"
    >
      <div style="min-height: 0">
        <div class="operations-block__collapse-content">
          <div>
            <span>{{getTranslation('Коины')}}:</span>
            <span>{{ transaction?.currency }}</span>
          </div>
          <div>
            <span>{{getTranslation('Сеть')}} </span>
            <span>{{ transaction?.network }}</span>
          </div>
          <div>
            <span>{{getTranslation('Адрес')}}:</span>
            <span>{{ transaction?.address }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import process from '@/assets/svg/process_operations.svg'
import success from '@/assets/svg/check-green.svg'
import failed from '@/assets/svg/failed_operations.svg'
import {mapGetters} from "vuex";

export default {
  props: {
    transaction: {}
  },
  data () {
    return {
      openOperationsBlock: false,
    }
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
    statusTransaction() {
      switch (this.transaction?.status) {
        case 'Created' || 'Pending':
          return { text: this.getTranslation('В обработке') , class: 'process', img: process }
        case 'Done' || 'Verified':
          return { text:this.getTranslation('Успешно') , class: 'success', img: success }
        case 'Failed':
          return { text: this.getTranslation('Отклонен'), class: 'failed', img: failed }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "operationBlock";
</style>