import { createStore } from 'vuex'
import auth from "./modules/auth";
import games from "./modules/games";
import notifications from "@/store/modules/notifications";
import rooms from "@/store/modules/rooms"
import tickets from "@/store/modules/tickets";
import wallet from "@/store/modules/wallet";
import settings from "@/store/modules/settings";
import referrals from "@/store/modules/referrals";
import payment from "@/store/modules/payments/payment"
import info from "@/store/modules/info";
import faq from "@/store/modules/faq"
import deposit from "@/store/modules/payments/deposit";
import withdrawal from "@/store/modules/payments/withdrawal";
import locale from "@/store/modules/locale";

export default createStore({
  modules: {
    auth,
    games,
    notifications,
    rooms,
    tickets,
    wallet,
    settings,
    referrals,
    payment,
    info,
    faq,
    deposit,
    withdrawal,
    locale
  },
  state() {
    return {

    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
})
