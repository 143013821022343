<template>
  <div class="tabs">
    <button
      class="tab"
      v-for="tab in translatedTabs"
      :key="tab.id"
      @click="openTab(tab)"
    >
      <img :src="tab.img" alt="" />
      <span>{{ tab.title }}</span>
      <div class="disable" :class="{ active: tab.id === selectedTab }"></div>
    </button>
    <!-- <LanguageSwitcher /> -->
  </div>
</template>

<script>
import Ticket from "@/assets/svg/ticket-fill.svg";
import HistoryPlay from "@/assets/svg/chat-history-fill.svg";
import Referral from "@/assets/svg/gift-sharp.svg";
import Wallet from "@/assets/svg/clarity_wallet-solid.svg";
import WorkHistory from "@/assets/svg/work-history.svg";
import Settings from "@/assets/svg/settings.svg";
import { mapGetters } from "vuex";
import LanguageSwitcher from "@/components/LanguageSwitcher/LanguageSwitcher.vue";
export default {
  components: { LanguageSwitcher },
  computed: {
    ...mapGetters("locale", ["getTranslation"]),
    translatedTabs() {
      return this.tabs.map((tab, index) => {
        switch (index) {
          case 0:
            tab.title = this.getTranslation("Доступные лотереи");
            break;
          case 1:
            tab.title = this.getTranslation("История участия");
            break;
          case 2:
            tab.title = this.getTranslation("Реферальная программа");
            break;
          case 3:
            tab.title = this.getTranslation("Пополнение/вывод средств");
            break;
          case 4:
            tab.title = this.getTranslation("История операций");
            break;
        }
        return tab;
      });
    },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          title: "",
          img: Ticket,
          link: "/dashboard/available-lotteries",
          id: 0,
        },
        {
          title: "",
          img: HistoryPlay,
          link: "/dashboard/history-participation",
          id: 1,
        },
        {
          title: "",
          img: Referral,
          link: "/dashboard/referral-program",
          id: 2,
        },
        {
          title: "",
          img: Wallet,
          link: "/dashboard/replenishment-withdrawal/deposit",
          id: 3,
        },
        {
          title: "",
          img: WorkHistory,
          link: "/dashboard/operations-history",
          id: 4,
        },
      ],
    };
  },
  methods: {
    openTab(tab) {
      this.$emit("close-burger");
      this.selectedTab = tab.id;
      this.$router.push(tab.link);
    },
  },
  mounted() {
    switch (this.$route.path) {
      case "/dashboard/available-lotteries":
        this.selectedTab = 0;
        break;
      case "/dashboard/history-participation":
        this.selectedTab = 1;
        break;
      case "/dashboard/referral-program":
        this.selectedTab = 2;
        break;
      case "/dashboard/replenishment-withdrawal/deposit":
        this.selectedTab = 3;
        break;
      case "/dashboard/replenishment-withdrawal/withdrawal":
        this.selectedTab = 3;
        break;
      case "/dashboard/operations-history":
        this.selectedTab = 4;
        break;
      case "/dashboard/settings":
        this.selectedTab = 5;
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "dashboardTabs";
</style>
