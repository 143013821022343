<template>
  <div class="head">
    <div class="main__head">
      <MainHeader
          @open-about-company="openAboutCompany= true"
          @toggle-burger="toggleBurger"
      />
      <div class="_container ">
        <div class="main__head-title">
          <h1>{{getTranslation('Твой ключ к захватывающему миру возможностей и невероятных выигрышей')}}</h1>
          <Button
              @click="registration"
          >
            {{getTranslation('Регистрация на платформе')}}
          </Button>
        </div>
      </div>
      <div class="_container">
        <img class="head__coin" src="@/assets/image/coin_bg-left.png" alt="coin">
      </div>
    </div>
  </div>
  <RouterView />
  <MainFooter
      @open-about-company="openAboutCompany = true"
      @open-terms="openTerms = true"
      @open-privacy="openPrivacy = true"
  />
  <ModalAboutCompany
      v-if="openAboutCompany"
      @close-modal="closeModal"
  />
  <ModalTerms
      v-if="openTerms"
      @close-modal="closeModal"
  />
  <ModalPrivacy
      v-if="openPrivacy"
      @close-modal="closeModal"
  />
  <MainBurger
      :is-burger="isBurger"
      @close-burger="isBurger = false"
      @open-about-company="openAboutCompany = true"
  />
</template>

<script>
import MainHeader from "@/components/layouts/Main/MainHeader/MainHeader.vue";
import Button from "@/components/UI/Button/Button.vue";
import MainFooter from "@/components/layouts/Main/MainFooter/MainFooter.vue";
import {mapGetters} from "vuex";
import ModalAboutCompany from "@/components/Modals/ModalAboutCompany/ModalAboutCompany.vue";
import MainBurger from "@/components/layouts/Main/MainBurger/MainBurger.vue";
import ModalTerms from "@/components/Modals/ModalTerms/ModalTerms.vue";
import ModalPrivacy from "@/components/Modals/ModalPrivacy/ModalPrivacy.vue";

export default {
  data () {
    return {
      isBurger: false,
      openAboutCompany: false,
      openTerms: false,
      openPrivacy: false,
    }
  },
  methods: {
    registration() {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/available-lotteries')
      } else {
        this.$router.push('/auth/registration')
      }
    },
    toggleBurger() {
      this.isBurger = !this.isBurger
    },
    closeModal() {
      this.openAboutCompany = false
      this.openTerms = false
      this.openPrivacy = false
    },
  },
  mounted() {
    this.$store.dispatch('locale/getlocales')
    this.$store.dispatch('locale/getCurrentLocale')
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('locale', ['getTranslation'])
  },
  components: {
    MainBurger,
    ModalAboutCompany,
    MainFooter,
    Button,
    MainHeader,
    ModalTerms,
    ModalPrivacy,
  }
}
</script>

<style lang="scss" scoped>
@import "mainLayout";
</style>