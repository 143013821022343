<template>
  <div class="settings">
    <div class="_container">
      <div class="settings__wrapper">
        <h2 class="settings__title">{{getTranslation('Настройки')}}</h2>
        <div class="settings__head">
          <div  class="settings__avatar">
            <div v-if="imageUrl" class="settings__avatar-img">
              <img :src="imageUrl" alt="avatar">
            </div>
            <div v-if="!imageUrl" class="settings__avatar-img">
              <img :src="user?.avatar" alt="avatar">
            </div>
            <input
                type="file"
                id="file"
                @change="handleFileUpload"
                accept="image/png, image/jpeg"
            >
            <label
                class="settings__avatar-edit"
                for="file"
            >
              <img src="@/assets/svg/pen-blue.svg" alt="pen">
            </label>
          </div>
          <div class="settings__info">
            <div
                v-if="!editUser"
                class="settings__rename"
            >
              <span class="settings__name">{{ user?.username }}</span>
              <button
                  @click="editUser = true"
                  class="settings__rename-button"
              >
                <img src="@/assets/svg/edit-name.svg" alt="">
              </button>
            </div>
            <span class="settings__name-mobile">{{ user?.username }}</span>
            <div
                v-if="editUser"
                class="settings__rename-open"
            >
              <input
                  type="text"
                  :placeholder="getTranslation('Новое имя пользователя')"
                  v-model="username"
              >
              <div class="settings__rename-btns">
                <button @click="changeUsername">{{getTranslation('Подтвердить')}}</button>
                <button @click="editUser = false">{{getTranslation('Отмена')}}</button>
              </div>
            </div>
            <span>{{getTranslation('Электронная почта:')}}
              <span>{{ user?.email }}</span>
            </span>
          </div>
        </div>

        <div class="settings__rename-mobile">
          <Button
              v-if="!editUser"
              @click="editUser = true"
          >
            {{getTranslation('Сменить имя пользователя')}}
          </Button>
          <div
              v-if="editUser"
              class="settings__rename-open__mobile"
          >
            <input
                type="text"
                :placeholder="getTranslation('Новое имя пользователя')"
                v-model="username"
            >
            <div class="settings__rename-btns">
              <button @click="changeUsername">{{getTranslation('Подтвердить')}}</button>
              <button @click="editUser = false">{{getTranslation('Отмена')}}</button>
            </div>
          </div>
        </div>

        <SettingsContainer
            @open-modal-change-password="$emit('open-modal-change-password')"
            @open-modal-change-email="$emit('open-modal-change-email')"
            @open-modal-2fa="$emit('open-modal-2fa')"
            @open-disabled-2fa="$emit('open-disabled-2fa')"
        />
        <button
            @click="logout"
            class="settings__exit-button"
        >
          <img src="@/assets/svg/settings-exit.svg" alt="">
          <span>{{getTranslation('Выйти из аккаунта')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsContainer from "@/components/SettingsContainer/SettingsContainer.vue";
import Button from "@/components/UI/Button/Button.vue";
import {changeUsername} from "@/api";
import {mapGetters} from "vuex";

export default {
  props: {
    user: {},
  },
  data () {
    return {
      selectedFile: null,
      imageUrl: null,
      editUser: false,
      username: ''
    }
  },
  methods: {
    logout() {
      document.cookie = "token=;  max-age=0;"

      localStorage.removeItem('authToken')
      localStorage.removeItem('user')

      this.$store.commit('auth/SET_AUTH', false)
      this.$router.push('/')
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0]
      this.imageUrl = URL.createObjectURL(this.selectedFile)

      this.$store.dispatch('settings/changeAvatar', { avatar: this.selectedFile })
    },
    changeUsername () {
      this.$store.dispatch('settings/changeUsername', { username: this.username })
      this.editUser = false
      this.username = ''
    }
  },
  mounted() {

  },
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  },
  components: {
    Button,
    SettingsContainer,
  }
}
</script>

<style lang="scss" scoped>
@import "settings";
</style>